import React from 'react'
import MenuProdCSS from './MenuProd.module.css'
import { Col, Row } from 'antd';
//import CantaritoChico from '../../images/cantaritochico.jpg'
import CantaritoChicoBack from '../../images/CantaritoChicoBack.jpg'
//import CantaritoMediano from '../../images/TestMenu.jpg'


function MenuProd(prod) {

    //console.log(prod)

    //var img = `${prod.prod.nombre.replace(' ', '').toLowerCase()}.6180c32e`

    ////console.log(CantaritoChicoBack)

    /*style={{backgroundImage:`url("/static/media/${img}.jpg")`,
                                                  backgroundSize:'cover',
                                                  backgroundPosition:'center'}}*/
  

    return (
        <div className={MenuProdCSS.card} style={{
            backgroundImage: `url(${CantaritoChicoBack})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            <Row onClick={prod.openProd.bind(this, prod.prod)}>
                <div className={MenuProdCSS.card_cont}>
                    <div className={MenuProdCSS.cardheader} >
                        {prod.prod.nombre}
                    </div>
                </div>
                <div className={MenuProdCSS.cardfooter}>
                    <Row>
                        <Col span={11} className={MenuProdCSS.cardfooter_off}>
                            {prod.prod.descuento.descuento ? `$${prod.prod.descuento.descuento} OFF` : ''}
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11} className={MenuProdCSS.cardfooter_sts}>
                            {prod.prod.estok > 0 ? 'Disponible' : 'Agotado'}
                        </Col>
                    </Row>
                </div>           
            </Row>
            
        </div>

    )
}

export default MenuProd
