import React, { useRef, useState } from 'react'
import { Form, Input, Button, Row, Col, Select } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import LoginCSS from './Login.module.css'
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { BASE } from '../../Constatnts/RestService'
import validator from 'validator'

const array = ["mesero", "chef", "cajero"];
const { Option } = Select;

function Register() {

    const [user, setUser] = useState();
    const [pass, setPass] = useState();
    const [rol, setRol] = useState();
    const [adminPass, setAdminPAss] = useState();

    const history = useHistory();

    const formRef = useRef();
    const inputUserRef = useRef();
    const inputPassRef = useRef();
    const inputPassAdmRef = useRef();

    const register = async () => {
        await axios.post(BASE + '/users/insert', [{ username: user, password: pass, rol: rol, estatus: "inactivo" }, { username: "", password: adminPass }])
            .then(function handleLogi(result) {
                if (result.data === true) {
                    history.push("/login");
                } else {
                    alert("No se pudo registrar el usuario, verifica que la contraseña del administrador sea la correcta");
                }
            }
            )
            .catch(function error(result) {
                alert("ocurrió un error en el registro");
                //console.log("error al registrar usuario:", result)
            });
        ////console.log("result ",result.data)
    }

    const handleUserChange = (event) => {
        setUser(event.target.value);
    }

    const handlePassChange = (event) => {
        setPass(event.target.value);
    }

    const handlePassAdmChange = (event) => {
        setAdminPAss(event.target.value)
    }

    const handleRegister = () => {
        if (user === undefined || user === "") {
            return alert("Ingresa un usuario")
        }
        if (pass === undefined || pass === "") {
            return alert("Ingresa una contraseña")
        }
        if (validator.isStrongPassword(pass, {
            minLength: 6, minNumbers: 1
        })) {
            return alert("La contraseña debe tener un minimo de 6 caracteres y un numero")
        }
        if (rol === undefined || rol === "Selecciona un rol de usuario") {
            return alert("Selecciona un rol de usuario")
        }
        if (adminPass === undefined || adminPass === "") {
            return alert("Ingresa la contraseña del administrador")
        }
        register()
        deleteForm();
    }

    const handleLogin = () => {
        deleteForm();
        history.push("/login");
    }

    //console.log("User ", user)
    //console.log("pass ", pass)
    //console.log("rol ", rol)
    //console.log("adm pass ", adminPass)

    const deleteForm = () => {
        formRef.current.resetFields();
        setUser("");
        setPass("");
        setRol("Selecciona un rol de usuario");
        setAdminPAss("");
        ////console.log(formRef.current);
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 4 },
            sm: { span: 20 },
        },
    };

    return (
        <div>
            <Row>
                <Col xs={1} sm={2} md={6} lg={7}></Col>
                <Col xs={22} sm={20} md={12} lg={10}>
                    <div>
                        <Row>
                            <Col span={2}></Col>
                            <Col span={20}>
                                <h1 className={LoginCSS.H1}>Registro de usuario</h1>
                            </Col>
                            <Col span={2}></Col>
                        </Row>
                    </div>
                    <Form ref={formRef} {...formItemLayout} className={LoginCSS.form}>
                        <Form.Item label="Usuario" className={LoginCSS.label}>
                            <Row>
                                <Col span={24}>
                                    <Input placeholder="Ingresa tu usuario" id="user" ref={inputUserRef} value={user} onChange={handleUserChange} />
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item label="Contraseña" className={LoginCSS.label}>
                            <Row>
                                <Col span={24}>
                                    <Input.Password
                                        placeholder="Ingresa tu contraseña" id="pass" ref={inputPassRef} value={pass} onChange={handlePassChange}
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                    {/* <Input placeholder="Ingresa tu contraseña" id="pass" ref={inputPassRef} value={pass} onChange={handlePassChange} /> */}
                                </Col>
                            </Row>
                        </Form.Item>

                        {/* <Space direction="vertical">
                            <Input.Password placeholder="input password" />
                            <Input.Password
                                placeholder="input password"
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Space> */}

                        <Form.Item label="Rol" className={LoginCSS.label}>
                            <Row>
                                <Col span={24}>
                                    <Select defaultValue="Selecciona un rol de usuario" name="Rol" value={rol} onChange={value => {
                                        setRol(value);
                                    }} >
                                        {array.map((item, index) => <Option key={index} value={item}>{item}</Option>)}
                                    </Select>
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item label="Contraseña del administrador" className={LoginCSS.label}>
                            <Row>
                                <Col span={24}>
                                    <Input.Password
                                        placeholder="Ingresa la contraseña del admin" id="passAdm" ref={inputPassAdmRef} value={adminPass} onChange={handlePassAdmChange}
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                    {/* <Input placeholder="Ingresa la contraseña del admin" id="passAdm" ref={inputPassAdmRef} value={adminPass} onChange={handlePassAdmChange} /> */}
                                </Col>
                            </Row>
                        </Form.Item>

                        <Form.Item style={{ textAlign: "center" }} wrapperCol={{
                            xs: { span: 4 },
                            sm: { span: 24 },
                        }} >
                            <Button type="primary" htmlType="submit" style={{ background: "#f56f3a", border: "1px solid #f56f3a" }} shape="round" block
                                onClick={handleRegister}>Registrarse</Button>
                        </Form.Item>
                    </Form>

                    <div className={LoginCSS.button_login}>
                        <button className={LoginCSS.login} onClick={handleLogin}>Login</button>
                    </div>

                </Col>
                <Col xs={1} sm={2} md={6} lg={7}></Col>
            </Row>
        </div>
    )
}

export default Register
