import React, { useState, useRef, useEffect } from 'react';
import TOrderCss from './TOrder.module.css';
import { Form, Input, Button, Row, Col, Select, List } from 'antd';
import 'antd/dist/antd.css';
import { useParams } from 'react-router-dom';
import { MinusCircleOutlined } from '@ant-design/icons';
import { BASE } from '../../Constatnts/RestService'

import InfiniteScroll from 'react-infinite-scroller';
import ModalView from '../Modal/ModalView';
import axios from 'axios';
import Cookies from 'universal-cookie';

const array = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const { Option } = Select;
const { TextArea } = Input;

function TOrder() {

    const { id } = useParams();
    const { mesaid } = useParams();

    const cookies = new Cookies();

    //console.log("Torder mesa: ", mesaid)

    //const [listProds, setListProds] = useState(id === undefined ? [] : testOrders.map((item) => item)[id].productos);
    const [listProds, setListProds] = useState([]);
    //console.log("list prods init: ", listProds)

    const [mesas, setMesas] = useState([]);
    const [mesa, setMesa] = useState();
    const [cantidad, setCantidad] = useState();
    const [productos, setProductos] = useState([]);
    const [producto, setProducto] = useState();
    const [selectProd, setSelectprod] = useState([]);
    const [descripcion, setDescripcion] = useState();
    const [estatus, setEstatus] = useState();
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState();
    const [total, setTotal] = useState(0);
    const [idEditProd, setIdEditProd] = useState("0");
    const [editProd, setEditProd] = useState(false);
    const [object, setObject] = useState({});
    const [editOrder, setEditOrder] = useState(false);

    useEffect(() => {
        if (cookies.get('username')) {
            //console.log("username, ", cookies.get('username'))
            document.getElementById("navbar").style.display = "block";
        } else {
            //console.log("no username")
            document.getElementById("navbar").style.display = "none";
        }

        const getMesas = async () => {
            const result = await axios.get(BASE + '/mesas/disponibles');
            setMesas(result.data)
            //console.log("consulta mesas ", result.data)
            if (mesaid !== undefined) {
                //setMesa({mesa:{id_mesa:mesaid}})
                ////console.log("values ",result.data.filter((item) => item.id_mesa === mesaid)[0])
                setMesa({ mesa: result.data.filter((item) => item.id_mesa === mesaid)[0] })
            }
        }

        const getProductosInvent = async () => {
            const result = await axios.get(BASE + '/prodsInvent/prodsInvent');
            setProductos(result.data)
        }

        const getOrderById = async () => {
            //console.log("ejecutando getOrderById: ");
            const result = await axios.get(BASE + '/orden/findById?id=' + id);
            //console.log("result rest: ", result.data)
            if (result.data !== "") {
                setListProds(result.data.producto);
                setMesa(result.data.mesa);
                setEditOrder(true);
            }
        }

        const getOrderByIdMesa = async () => {
            //console.log("ejecutando getOrderByIdMesa: ");
            const result = await axios.get(BASE + '/orden/findById?id=' + mesaid);
            //console.log("result rest:", result.data, "<")
            if (result.data !== "") {
                setListProds(result.data.producto);
                setMesa(result.data.mesa);
                setEditOrder(true);
            }

        }


        setSelectprod("Selecciona un producto")

        getMesas();
        getProductosInvent();
        if (id !== undefined) {
            getOrderById();
        }
        if (mesaid !== undefined) {
            getOrderByIdMesa();
        }

        if (listProds.length === 0 || listProds === undefined) {
            setEditProd(false)
            //setIdEditProd(0)
            deleteForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    /*//console.log("Mesas: ",mesas)
    //console.log("Productos Invent: ",productos)
    //console.log("OrderById: ",listProds)*/

    const formRef = useRef();
    const textAreaRef = useRef();

    const handleAddOrder = () => {
        if (listProds.length === 0) {
            setModalType("ProdsError");
            setModal(true);
            return;
        }
        if (mesa === undefined && id === undefined) {
            setModalType("MesaError");
            setModal(true);
            return;
        }


        //setObject()
        //console.log("mesa", mesa)
        //console.log("producto ", producto)
        //console.log("productos ", productos)
        //console.log("valores de listprod", listProds)
        const newObject = { id: mesa.mesa.id_mesa, estatus: "en proceso", mesa: { id_mesa: mesa.mesa.id_mesa }, producto: listProds }
        //console.log("nuevo objeto", newObject)


        setObject(newObject);
        setModalType("Prod");
        setModal(true);
        sumaProds();
    }

    const handleModalDeletProd = () => {
        setModalType("deleteProd");
        setModal(true);
    };

    const showMesa = (
        id !== undefined || mesaid !== undefined ?
            { display: "none" }
            :
            {}
    );

    const sumaProds = () => {
        setTotal(listProds.map((item) =>
            ((item.productosInvent.precio) * (item.cantidad))).reduce((previous, current) => { return previous + current }));
    }

    const handleAddProducts = () => {

        ////console.log(`Datos ${cantidad} ${producto.producto.value} ${descripcion} ${selectProd}`);

        if ((cantidad === undefined || cantidad === 'Cant') || (producto === undefined || selectProd === "Selecciona un producto")) {
            //console.log('entra if');
            return;
        }

        //console.log("list prods",productos[0].id_prod)
        //console.log("producto key",producto.producto.key)
        //console.log("precio ", productos.find((item) => item.id_prod === producto.producto.key).precio);

        setListProds((prevProds) => {

            return [...prevProds, {
                id_prod_orden: listProds.length,
                cantidad: cantidad,
                productosInvent: {
                    id_prod: producto.producto.key,
                    nombre: producto.producto.children,
                    //fix 2 para corregir el monto total de la suma en el modal al agregar la orden - Componente TOrder
                    precio: productos.find((item) => item.id_prod === producto.producto.key).precio
                    //precio: producto.producto.value
                },
                descripcion_orden: descripcion,
                estatus_orden: "sin entregar"
            }
            ];
        });
        deleteForm();

    }

    const handleChange = (event) => {
        setDescripcion(event.target.value);
    }

    const deleteForm = () => {
        formRef.current.resetFields();
        setCantidad("Cant");
        setSelectprod("Selecciona un producto");
        setDescripcion("");
        ////console.log(formRef.current);
    }

    const deletProd = (id) => {
        //console.log("id delete prod ", id)
        const newListProds = listProds.filter(producto => producto.id_prod_orden !== id);
        setListProds(newListProds);
        //
        setEditProd(false)
        deleteForm();
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 4 },
            sm: { span: 20 },
        },
    };

    const handleInfiniteOnLoad = () => {

    };

    const closeModal = () => {
        setModal(false);
        ////console.log("cerrando modal");
    }

    // //console.log("----------------------------")
    // //console.log("lista de prod ", listProds)
    // //console.log("valor de idedit", idEditProd + "")
    // //console.log("valor de edit prod ", editProd)
    // //console.log("----------------------------")

    
    const editProdBody = (
        listProds !== undefined ?
            listProds.length !== 0 && idEditProd + "" !== undefined && editProd === false ?
                <div>
                    <div style={{ textAlign: "center" }}>No es posible editar un producto que fue cancelado, entregado, en preparacion o ¡liso!</div>
                    <br></br>
                    {/* <div style={{ textAlign: "center" }}>Esatus del producto: {listProds[idEditProd].estatus}</div> */}
                    <div style={{ textAlign: "center" }}>Esatus del producto: {estatus}</div>

                </div>
                :
                <div></div>
            :
            <div></div>
    );

    const modalView = (
        <div>{modal === false ? <div></div> :
            modalType === "Prod" ?
                <div>
                    <ModalView datos={{
                        id: id === undefined ?
                            mesaid === undefined ?
                                "/home"
                                :
                                "/OrderCtrl/" + mesaid
                            :
                            id,
                        edit: editOrder,
                        modal: modal, header: id === undefined ? mesa : "Lista actual", modalType: modalType, productos: listProds, total: total, function: closeModal
                    }} closeModal={closeModal} object={object} ></ModalView>
                </div>
                :
                modalType === "MesaError" ?
                    <div>
                        <ModalView datos={{ modal: modal, header: "¡Error!", modalType: modalType, body: "Debes seleccionar una mesa antes de realizar el pedido", function: closeModal }} closeModal={closeModal} ></ModalView>
                    </div>
                    :
                    modalType === "ProdsError" ?
                        <div>
                            <ModalView datos={{ modal: modal, header: "¡Error!", modalType: modalType, body: "Debes agregar al menos un producto antes de realizar el pedido", function: closeModal }} closeModal={closeModal} ></ModalView>
                        </div>
                        :
                        modalType === "deleteProd" ?
                            <div>
                                <ModalView datos={{ modal: modal, header: "¡Permiso denegado!", modalType: modalType, body: "No es posible borrar un prroducto, solo puedes agregar o editar productos", function: closeModal }} closeModal={closeModal} ></ModalView>
                            </div>
                            :
                            modalType === "editError" ?
                                <div>
                                    <ModalView datos={{ modal: modal, header: "¡Permiso denegado!", modalType: modalType, body: editProdBody, function: closeModal }} closeModal={closeModal} ></ModalView>
                                </div>
                                : <div></div>}
        </div>
    );

    const changeValue = (val) => {
        //console.log("editando producto ", val)
        //console.log("lista de productos ", listProds)
        //console.log("edit prod ", editProd)
        //console.log("id edit prod ", idEditProd + "")

        setIdEditProd(val.idProd + "");
        setCantidad(val.cantidad);
        setSelectprod(val.producto);
        setEstatus(val.estatus);
        setDescripcion(val.descripcion);

        if (val.estatus !== "cancelado") {

            const text = document.querySelector("#TextArea");

            var nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value").set;
            nativeTextAreaValueSetter.call(text, val.descripcion);

            const event = new Event('input', { bubbles: true });
            text.dispatchEvent(event);


            setEditProd(true);
        } else {
            setModalType("editError");
            setModal(true);
            setEditProd(false)
        }
    }

    const handleEditProducts = () => {
        //console.log("entrndo a handleEditProducts")
        if ((cantidad === undefined || cantidad === 'Cant' || selectProd === "Selecciona un producto")) {
            //console.log('entra if');
            return;
        }

        //object.producto.filter((item) => item.estatus_orden === "¡listo!")
        if (listProds.length === 0 || listProds.filter((item) => item.id_prod_orden === idEditProd + "")[0].estatus_orden !== "sin entregar") {
            setModalType("editError");
            setModal(true);
            setEditProd(false);
            deleteForm();
            return;
        }

        //console.log("valores cantidad", cantidad)
        //console.log("valores selectProd", selectProd)
        //console.log("valores descripcion", descripcion)
        //console.log("producto ", producto)
        //console.log("valores de estatus", estatus)
        //console.log("valores de id", idEditProd + "")

        const newListprod = listProds.filter((item) => item.id_prod_orden === idEditProd + "")[0];
        //console.log("nueva lista prod ", newListprod)
        newListprod.id_prod_orden = idEditProd + "";
        newListprod.cantidad = cantidad;
        newListprod.estatus_orden = estatus;
        if (producto !== undefined) {
            newListprod.productosInvent = { id_prod: producto.producto.key, nombre: producto.producto.children, precio: producto.producto.value };
        }
        newListprod.descripcion_orden = descripcion;
        //console.log("nueva lista prod fill", newListprod)

        ////console.log("test ", listProds.map((item) => (item.id_prod_orden === idEditProd ? newListprod : item)));
        setListProds(listProds.map((item) => (item.id_prod_orden === idEditProd + "" ? newListprod : item)));

        if (producto !== undefined) {
            //console.log("producto undefined")
            //listProds[idEditProd].productosInvent.precio = producto.producto.value;
            newListprod.precio = producto.producto.value;
        }

        setCantidad(undefined);
        setEstatus(undefined);
        setSelectprod(undefined);
        setDescripcion(undefined);
        setProducto(undefined);
        setIdEditProd("0");
        setEditProd(false);
        deleteForm();

        //console.log("lista por enviar ", listProds)
    }

    //Fix 1 para seleccionar los productos correctamente - Componente TOrder
    function handleChangeSelectProd(index,children){
        //console.log("selected",index)
        //console.log("selected",children)
        setSelectprod(children.children);
        setProducto({ producto: children });
    }

    ////console.log("valor de cantidad",listProds.filter((item) => item.id_prod_orden === idEditProd))

    return (
        <div className={TOrderCss.products_section}>
            <Row>
                <Col xs={1} sm={2} md={6} lg={7}></Col>
                <Col xs={22} sm={20} md={12} lg={10}>
                    <div style={
                        listProds !== undefined ?
                            listProds.length === 0 ?
                                { display: "block" }
                                :
                                { display: "none" }
                            :
                            { display: "block" }
                    }>
                        <Row>
                            <Col span={2}></Col>
                            <Col span={20}>
                                <h1 className={TOrderCss.H1}>No hay productos agregados</h1>
                            </Col>
                            <Col span={2}></Col>
                        </Row>
                    </div>
                    <div className={` ${TOrderCss.products} `} style={
                        listProds !== undefined ?
                            listProds.length === 0 ? { display: "none" } : { display: "block" }
                            :
                            { display: "block" }
                    } >
                        <div className={` ${TOrderCss.demo_infinite_container} `}>
                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={0}
                                loadMore={handleInfiniteOnLoad}
                                /*hasMore={!state.loading && state.hasMore}*/
                                useWindow={false}>
                                <List
                                    header={<Row gutter={[16, 16]}>
                                        <Col span={2} className={`${TOrderCss.grid} ${TOrderCss.grid_left}`}>No.</Col>
                                        <Col span={10} className={TOrderCss.grid}>Producto</Col>
                                        <Col span={10} className={`${TOrderCss.grid} `}>Descripción</Col>
                                        <Col span={2} className={`${TOrderCss.grid} ${TOrderCss.grid_right}`}>x</Col>
                                    </Row>}
                                    dataSource={listProds}
                                    renderItem={(item, index) => (
                                        <List.Item key={index} >
                                            <Col span={2} style={{ paddingLeft: 10 }} >{item.cantidad}</Col>
                                            <Col span={10} className={TOrderCss.grid_list} onClick={changeValue.bind(this, { idProd: item.id_prod_orden, cantidad: item.cantidad, producto: item.productosInvent.nombre, descripcion: item.descripcion_orden, estatus: item.estatus_orden })} >{item.productosInvent.nombre}</Col>
                                            <Col span={10} className={TOrderCss.grid_list} onClick={changeValue.bind(this, { idProd: item.id_prod_orden, cantidad: item.cantidad, producto: item.productosInvent.nombre, descripcion: item.descripcion_orden, estatus: item.estatus_orden })} >{item.descripcion_orden}</Col>
                                            <Col span={2} className={`${TOrderCss.grid_list} ${TOrderCss.grid_list_del}`}><MinusCircleOutlined onClick={id === undefined ? deletProd.bind(this, item.id_prod_orden) : handleModalDeletProd} /></Col>
                                        </List.Item>
                                    )}>
                                </List>
                            </InfiniteScroll>
                        </div>
                    </div>

                    <Form ref={formRef} {...formItemLayout} className={TOrderCss.form}>
                        <Form.Item label="No. de mesa" className={TOrderCss.label} style={showMesa}>
                            <Row>
                                <Col span={24}>
                                    <Select defaultValue="Selecciona la mesa" name="mesa" onChange={(value, index) => {
                                        setMesa({ mesa: { id_mesa: index.key, mesa: value } });
                                    }} >
                                        {mesas.map((item) => <Option key={item.id_mesa} value={item.mesa} >{item.mesa}</Option>)}
                                    </Select>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item label="Producto" className={TOrderCss.label}>
                            <Row>
                                <Col span={7}>
                                    <Select defaultValue="Cant" name="cantidad" value={cantidad} onChange={value => {
                                        setCantidad(value);
                                    }} >
                                        {array.map((item) => <Option key={item} value={item} >{item}</Option>)}
                                    </Select>
                                </Col>
                                <Col span={17}>
                                    <Select defaultValue="Selecciona un producto" name="producto" value={selectProd} 
                                    onChange={(index, children) => handleChangeSelectProd(index,children)}>
                                    {/* // onChange={(value, children) => {
                                    //     setSelectprod(children.children);
                                    //     //console.log("producto: ", children);
                                    //     setProducto({ producto: children });
                                    // }}> 
                                        {productos.map((item) => <Option key={item.id_prod} value={item.precio} >{item.nombre}</Option>)}
                                        */}
                                        {productos.map((item) => <Option key={item.id_prod} value={item.id_prod} >{item.nombre}</Option>)}
                                    </Select>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item label="Especificaciones" name="especificaciones" className={TOrderCss.label}>
                            <TextArea placeholder="Ingresa alguna especificación" id="TextArea" ref={textAreaRef} autoSize value={descripcion} onChange={handleChange} />
                        </Form.Item>
                        {editProd === true ?
                            <Form.Item style={{ textAlign: "center" }} wrapperCol={{
                                xs: { span: 4 },
                                sm: { span: 24 },
                            }} >
                                <Button type="primary" htmlType="submit" style={{ background: "#f56f3a" }} shape="round"
                                    onClick={handleEditProducts.bind()}>Editar producto</Button>
                            </Form.Item>
                            :
                            <Form.Item style={{ textAlign: "center" }} wrapperCol={{
                                xs: { span: 4 },
                                sm: { span: 24 },
                            }} >
                                <Button type="primary" htmlType="submit" style={{ background: "#f56f3a" }} shape="round"
                                    onClick={handleAddProducts}>Agregar pedido</Button>
                            </Form.Item>
                        }
                        <Form.Item style={{ textAlign: "center" }} wrapperCol={{
                            xs: { span: 4 },
                            sm: { span: 24 },
                        }} >
                            <Button type="primary" htmlType="submit" style={{ background: "#252932" }} shape="round" block
                                onClick={handleAddOrder}>
                                Tomar la orden
                            </Button>
                        </Form.Item>
                    </Form>
                    <div>
                        {modalView}
                    </div>
                </Col>
                <Col xs={1} sm={2} md={6} lg={7}></Col>
            </Row>
        </div>
    )
}

export default TOrder;