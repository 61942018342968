import React from 'react';
import CardsCSS from './Cards.module.css';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

export default function Card({ datos }) {

    const background = (
        datos.estatus === "en proceso" ?
            //{ background: "#C70039"}
            { background: "#DF3A01" }
            :
            datos.estatus === "atendiendo" ?
                // { background: "#DF3A01" }
                { background: "#DBA901" }
                :
                // datos.estatus === "atendido" ?
                //{ background: "#DBA901"}
                { background: "#298A08" });
    //:
    //{ background: " #298A08" });
    //{ background: " " });

    //console.log("datos card: ", datos)

    return (

        <div className={CardsCSS.card} >
            <div className={CardsCSS.card_cont}>
                <div className={CardsCSS.cardheader}>
                    {`${datos.mesa.mesa}`}
                </div>
                <div className={CardsCSS.cardbody}>
                    <div className={CardsCSS.overflow}>
                        {datos.producto.map((subitem, index) =>
                            <p key={index}>{subitem.cantidad} {subitem.productosInvent.nombre} {subitem.descripcion_orden}</p>
                        )}
                    </div>

                    <div>
                        <Row>
                            <Col span={24}>
                                {/* {//console.log(datos.id)} */}
                                <Link to={`OrderCtrl/${datos.id}`}>Revisar</Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className={CardsCSS.cardfooter} style={background}>
                {datos.estatus}
            </div>
        </div>
    )
}
