import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import OrderCtrl from './OrderCtrl';
//import BKPOrderCtrl from './BKPOrderCtrl';
import axios from 'axios';
import {BASE} from '../../Constatnts/RestService'

function GetDataOrder  () {

    const [list, setList] = useState([]);
    const { id } = useParams();
    ////console.log("ID", id)

    useEffect(() => {
        const getOrdenes = async () => {
            const result = await axios.get(BASE+'/orden/ordenes');
            setList(result.data)
            //console.log("datos: ", result.data)
        }
        getOrdenes();
    }, [])

    /*//console.log("con filter ",list.filter((item) => item.id === id)[0]);
    //console.log("con map ",list.map((item) => item)[0])
    //console.log("con map y filter ", list.filter((item) => item.id === id).map((item) => item)[0])*/

    return (
        <div>{list.filter((item) => item.id === id).map((item,index) => <OrderCtrl key={item.id} data={list} id={id}/>)}</div>
       //<div>{list.map((item) => <OrderCtrl data={list} id={id}/>)[id]}</div>
    )
}

export default GetDataOrder
