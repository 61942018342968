import { React, useEffect, useState } from 'react';
import {BASE} from '../../Constatnts/RestService';
import axios from 'axios';
import Cookies from 'universal-cookie';

import Card from './Card';
import CardsCSS from './Cards.module.css';

function Cards() {

    const [list,setList] = useState([]);

    const cookies = new Cookies();

    useEffect(() => {
        if(cookies.get('username')){
            //console.log("username, ",cookies.get('username'))
            document.getElementById("navbar").style.display = "block";
        }else{
            //console.log("no username")
            document.getElementById("navbar").style.display = "none";
        }
        const getOrdenes = async () => {
            const result = await axios.get(BASE+'/orden/ordenes');
            //console.log("datos: ",result.data)
            setList(result.data);
        }

        getOrdenes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <section className={CardsCSS.cardsection}>
            {list.map((item) => <Card key={item.id} datos={item} indice={item.id} />)}
        </section>
    )
}

export default Cards;
