import React, { useEffect, useState } from 'react'
import MenuCSS from './Menu.module.css'
import MenuProd from './MenuProd'
import MenuProdCSS from './MenuProd.module.css'
import { BASE } from '../../Constatnts/RestService'
import axios from 'axios';
import ShowMenu from './ShowMenu';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';

function Menu() {

    const history = useHistory();
    const cookies = new Cookies();

    const [productos, setProductos] = useState([]);
    const [prodModal, setProdModal] = useState({});
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if(cookies.get('username')){
            //console.log("username, ",cookies.get('username'))
            document.getElementById("navbar").style.display = "block";
        }else{
            //console.log("no username")
            document.getElementById("navbar").style.display = "none";
        }
        getProductosInvent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { mesa } = useParams();

    //console.log("menu mesa: ", mesa)

    const openProd = (prod) => {
        //console.log("Modal enable: ", modal)
        //console.log("Modal datos: ", prod)
        setProdModal(prod);
        setModal(true);

    }

    const closeModal = () => {
        setModal(false);
        //console.log("cerrando modal");
    }

    const action = () => {
        if(mesa === undefined){
            history.push("/Torder");
        }else{
            history.push("/Torder/mesa/"+mesa);
        }
    }

    //console.log("modal: ", modal)

    const getProductosInvent = async () => {
        const result = await axios.get(BASE + '/prodsInvent/prodsInvent');
        setProductos(result.data)
    }

    const modalView = (
        <div>
            {modal === false ?
                <div></div>
                :
                <ShowMenu datos={{ modal: modal, prod: prodModal, mesa: mesa, function: closeModal }} closeModal={closeModal} className={MenuCSS.container}></ShowMenu>
            }
        </div>
    )

    return (
        <div >

            <section className={MenuCSS.section}>
                <div className={MenuCSS.slider}>
                    <div className={MenuCSS.slider_container}>
                        <div className={`${MenuCSS.image_container} ${MenuCSS.Slider1}`} />
                        <div className={`${MenuCSS.image_container} ${MenuCSS.Slider2}`} />
                        <div className={`${MenuCSS.image_container} ${MenuCSS.Slider3}`} />
                        <div className={`${MenuCSS.image_container} ${MenuCSS.Slider4}`} />
                    </div>
                </div>
                <div className={MenuCSS.div_float_button}>
                    <button className={MenuCSS.float_button} onClick={action}>Realiza tu orden</button>
                </div>
            </section>
                
            <section className={MenuProdCSS.cardsection}>
                {productos.map((item, index) => <MenuProd key={index} prod={item} openProd={openProd} />)}
                <br></br>
            </section>

            <div>{modalView}</div>
        </div>
    )
}

export default Menu
