import { Modal, Col, Row, Button } from 'antd';
import React, { useState } from 'react'
import ModalCss from './Modal.module.css';
import TOrderCss from '../TOrder/TOrder.module.css';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { BASE } from '../../Constatnts/RestService'

function ModalView(datos) {

    const [modal, setModal] = useState(datos.datos.modal);
    const history = useHistory();

    const cookies = new Cookies();

    //console.log("datos de modal: ", datos);
    /*//console.log(datos.datos.header);
    //console.log(datos.datos.productos);*/

    const cerrarModal = () => {
        setModal(false);
        datos.closeModal();
    }

    const insertOrder = async (id, object) => {
        await axios.post(BASE + '/orden/insert?id=' + id, object);
        //console.log("resultado de la insercion: ", response)
    }

    const editOrderProd = async (object, id) => {
         await axios.put(BASE + '/prodsOrder/edit?id=' + id, object);
        //console.log("resultado de la actualización: ", response)
        //console.log("datos para editar producto ", object)
        //console.log("id para editar producto ", id)
    }

    const cobrarOrder = async (id) => {
        //console.log("id para editar producto ", id)
        await axios.delete(BASE + '/orden/collect?id=' + id)
        //console.log("resultado de la eliminacion: ", response.data)

    }

    const action = () => {
        if (datos.datos.modalType === "MesaError") {
            //console.log(`Close by ${datos.datos.modalType}`);
            cerrarModal();
            return;
        }
        if (datos.datos.modalType === "ProdsError") {
            //console.log(`Close by ${datos.datos.modalType}`);
            cerrarModal();
            return;
        }
        if (datos.datos.modalType === "deleteProd") {
            //console.log(`Close by ${datos.datos.modalType}`);
            cerrarModal();
            return;
        }
        if (datos.datos.modalType === "cancelProd") {
            //console.log(`Close by ${datos.datos.modalType}`);
            cerrarModal();
            return;
        }
        if (datos.datos.modalType === "entregaChefProd") {
            //console.log(`Close by ${datos.datos.modalType}`);
            cerrarModal();
            return;
        }
        if (datos.datos.modalType === "entregaMeseroProd") {
            //console.log(`Close by ${datos.datos.modalType}`);
            cerrarModal();
            return;
        }
        if (datos.datos.modalType === "editError") {
            //console.log(`Close by ${datos.datos.modalType}`);
            cerrarModal();
            return;
        }
        if (datos.datos.modalType === "prepareProd") {
            //console.log(`Close by ${datos.datos.modalType}`);
            cerrarModal();
            return;
        }
        if (datos.datos.modalType === "addCode") {
            //console.log(`Close by ${datos.datos.modalType}`);
            cerrarModal();
            return;
        }
        if (datos.datos.modalType === "cobrarOrder") {
            //console.log(`Close by ${datos.datos.modalType}`);
            //console.log("datos -->", datos.datos.productos.producto.length)
            if (datos.datos.productos.producto.filter((item) => item.estatus_orden === "cancelado" || item.estatus_orden === "entregado").length === datos.datos.productos.producto.length) {
                //console.log("entra if cobrar")
                //console.log("total", datos.datos)
                cobrarOrder(datos.datos.productos.id);
                setTimeout(() => {
                    history.push("/home");
                }, 1000);
            }
            cerrarModal();
            return;
        }
        if (datos.datos.modalType === "cancelOrder") {
            //console.log(`Close by ${datos.datos.modalType}`);
            cerrarModal();
            return;
        }

        //if (datos.datos.id === "/home") {
        //console.log("includes id ", datos.datos.id)
        //console.log("#### bandera para saber si debo editar", datos.datos.edit)
        //console.log("productos: ", datos.datos.productos,)
        if (datos.datos.id.includes("/home")) {
            insertOrder(datos.object.id, datos.object)
        } else {
            if (datos.datos.id.includes("/OrderCtrl/")) {
                if (datos.datos.edit) {
                    editOrderProd(datos.datos.productos, datos.datos.id.replace("/OrderCtrl/", ""))
                } else {
                    insertOrder(datos.datos.id.replace("/OrderCtrl/", ""), datos.object)
                }
            } else {
                editOrderProd(datos.datos.productos, datos.datos.id)
            }
        }

        setTimeout(() => {
            //history.push(datos.datos.id === "/home" ? "/home" : `/OrderCtrl/${datos.datos.id}`);

            history.push(datos.datos.id.includes("/OrderCtrl/") || datos.datos.id.includes("/home") ?
                cookies.get('username') ?
                    datos.datos.id
                    :
                    `/OrderCtrl/${datos.object.id}`
                :
                `/OrderCtrl/${datos.datos.id}`);
        }, 1000);

        cerrarModal();

    }

    //console.log("datos del header", datos)

    return (
        <div>

            <Modal title={
                <h1 className={ModalCss.title}>{
                    datos.datos.id ?
                        datos.datos.id.includes("/OrderCtrl/") || datos.datos.id.includes("/home") ?
                            datos.datos.header.mesa.mesa
                            : datos.datos.header
                        : datos.datos.header
                }</h1>
                /*<h1 className={ModalCss.title}>{datos.datos.header.mesa.mesa}</h1>*/
            }
                visible={modal}
                onCancel={cerrarModal}
                onOk={action}
                centered
                bodyStyle={{ padding: "15px" }}
                footer={
                    <Row>
                        <Col span={12}>
                            <Button block
                                className={ModalCss.button_cancel}
                                onClick={cerrarModal}>Cancelar</Button>
                        </Col>
                        <Col span={12}>
                            <Button block
                                className={ModalCss.button_ok}
                                onClick={action}>OK</Button>
                        </Col>
                    </Row>
                }>
                <div>
                    {datos.datos.modalType === "Prod" ?
                        <div >
                            <Row>
                                <Col span={2} className={`${TOrderCss.grid} ${TOrderCss.grid_left}`}>No.</Col>
                                <Col span={10} className={TOrderCss.grid}>Producto</Col>
                                <Col span={8} className={`${TOrderCss.grid} `}>Descripción</Col>
                                <Col span={4} className={`${TOrderCss.grid} ${TOrderCss.grid_right}`}>Precio</Col>
                            </Row>
                            <br></br>
                            {datos.datos.productos.map((item, index) =>
                                <Row key={index}>
                                    <Col span={2} className={TOrderCss.grid_list} >{item.cantidad}</Col>
                                    <Col span={10} className={TOrderCss.grid_list}>{item.productosInvent.nombre}</Col>
                                    <Col span={8} className={TOrderCss.grid_list}>{item.descripcion_orden}</Col>
                                    <Col span={4} className={TOrderCss.grid_list}>${item.productosInvent.precio}</Col>
                                </Row>)}
                            <br></br>
                            <footer >
                                <Row >
                                    <Col span={6} className={TOrderCss.grid_list}><h2>Total:</h2></Col>
                                    <Col span={6}></Col>
                                    <Col span={6}></Col>
                                    <Col span={6} className={TOrderCss.grid_list}><h2>${datos.datos.total}</h2></Col>
                                </Row>
                            </footer>
                        </div>
                        :
                        datos.datos.modalType === "MesaError" || datos.datos.modalType === "ProdsError" || datos.datos.modalType === "deleteProd" || datos.datos.modalType === "cancelProd" || datos.datos.modalType === "entregaChefProd" || datos.datos.modalType === "editError" || datos.datos.modalType === "entregaMeseroProd" || datos.datos.modalType === "prepareProd" || datos.datos.modalType === "cobrarOrder" || datos.datos.modalType === "cancelOrder" || datos.datos.modalType === "addCode" ?
                            <div>
                                {datos.datos.modalType === "cancelProd" || datos.datos.modalType === "entregaChefProd" || datos.datos.modalType === "editError" || datos.datos.modalType === "entregaMeseroProd" || datos.datos.modalType === "prepareProd" || datos.datos.modalType === "cobrarOrder" || datos.datos.modalType === "cancelOrder" || datos.datos.modalType === "addCode" ?
                                    <div>{datos.datos.body}</div>
                                    :
                                    <p className={TOrderCss.grid_list}>{datos.datos.body}</p>}
                            </div>
                            :
                            <div></div>
                    }
                </div>
            </Modal>
        </div >
    )
}

export default ModalView;