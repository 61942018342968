import React, { useEffect } from 'react'
import WelcomeCSS from './Welcome.module.css'
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import image from '../../images/Cantarito_inicio.png'

function Welcome() {

    const cookies = new Cookies();

    useEffect(() => {
        if(cookies.get('username')){
            //console.log("username, ",cookies.get('username'))
            document.getElementById("navbar").style.display = "block";
        }else{
            //console.log("no username")
            document.getElementById("navbar").style.display = "none";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { mesa } = useParams();

    //console.log("welcome mesa: ", mesa)

    return (
        <div>
            <section className={WelcomeCSS.inicio}>
                <div>
                    <h2 className={WelcomeCSS.h2}>Listo para darte un Atorón?...</h2>
                </div>
                <div className={WelcomeCSS.imagen}>
                    <img className={WelcomeCSS.inicio_img} src={image} alt=""></img>
                </div>
                <div>
                    <h3 className={WelcomeCSS.h3}>Conoce la variedad de productos que tenemos para ofrecerte</h3>
                    {mesa ?
                        <a href={"/menu/" + mesa} className={WelcomeCSS.btn}>Revisar menú</a>
                        :
                        <a href="/menu" className={WelcomeCSS.btn}>Revisar menú</a>
                    }
                    <a href="/login" className={WelcomeCSS.btn}>Iniciar sesión</a>
                </div>
            </section>
        </div>
    )
}

export default Welcome
