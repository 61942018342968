import React, { useRef, useState } from 'react'
import { Form, Input, Button, Row, Col } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import axios from 'axios';
import { BASE } from '../../Constatnts/RestService'
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import LoginCSS from './Login.module.css'

function Login() {

    const [user, setUser] = useState();
    const [pass, setPass] = useState();

    const formRef = useRef();
    const inputUserRef = useRef();
    const inputPassRef = useRef();

    const history = useHistory();

    const cookies = new Cookies();

    const login = async () => {
        deleteForm();
        await axios.post(BASE + '/users/login', { username: user, password: pass })
            .then(function handleLogi(result) {
                if (result.data.filter((item) => item.result === true)[0]) {
                    ////console.log("data: ", result.data.filter((item) => item.username === user)[0])
                    const res = result.data.filter((item) => item.username === user)[0];

                    cookies.set('id_user', res.id_user, {path:"/"})
                    cookies.set('username', res.username, {path:"/"})
                    cookies.set('rol', res.rol, {path:"/"})
                    cookies.set('estatus', res.estatus, {path:"/"})
                    history.push("/user");
                } else {
                    alert("usuario o password incorrectos");
                }
            }
            );
        ////console.log("result ",result.data)
    }

    const handleUserChange = (event) => {
        setUser(event.target.value);
    }

    const handlePassChange = (event) => {
        setPass(event.target.value);
    }

    const handleRegister = () => {
        deleteForm();
        history.push("/register");
    }

    const deleteForm = () => {
        formRef.current.resetFields();
        setUser("");
        setPass("");
        ////console.log(formRef.current);
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 4 },
            sm: { span: 20 },
        },
    };

    return (
        <div>
            <Row>
                <Col xs={1} sm={2} md={6} lg={7}></Col>
                <Col xs={22} sm={20} md={12} lg={10}>
                    <div>
                        <Row>
                            <Col span={2}></Col>
                            <Col span={20}>
                                <h1 className={LoginCSS.H1}>Inicio de sesion</h1>
                                <h3 className={LoginCSS.H3}>Los roles establecidos son unicamente para el personal del establecimiento,
                                    queda prohibido cambiar de usuario a menos que sea indicado por el administrador,
                                    debes tener en cuenta que toda la actividad de la pagina queda registrada en el
                                    servidor por lo que cualquir actividad ilícita será detectada.</h3>
                            </Col>
                            <Col span={2}></Col>
                        </Row>
                    </div>
                    <Form ref={formRef} {...formItemLayout} className={LoginCSS.form}>
                        <Form.Item label="Usuario" className={LoginCSS.label}>
                            <Row>
                                <Col span={24}>
                                    <Input placeholder="Ingresa tu usuario" id="user" ref={inputUserRef} value={user} onChange={handleUserChange} />
                                </Col>
                            </Row>
                        </Form.Item>
                        
                        <Form.Item label="Contraseña" className={LoginCSS.label}>
                            <Row>
                                <Col span={24}>
                                    {/* <Input placeholder="Ingresa tu contraseña" id="pass" ref={inputPassRef} value={pass} onChange={handlePassChange} /> */}
                                    <Input.Password
                                placeholder="Ingresa tu contraseña" id="pass" ref={inputPassRef} value={pass} onChange={handlePassChange}
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                                </Col>
                            </Row>
                        </Form.Item> 

                        <Form.Item style={{ textAlign: "center" }} wrapperCol={{
                            xs: { span: 4 },
                            sm: { span: 24 },
                        }} >
                            <Button type="primary" htmlType="submit" style={{ background: "#f56f3a" }} shape="round" block
                                onClick={login}>
                                Iniciar sesion
                            </Button>
                        </Form.Item>
                    </Form>

                    <div className={LoginCSS.button_login}>
                        <button className={LoginCSS.login} onClick={handleRegister}>Registrarse</button>
                    </div>

                </Col>
                <Col xs={1} sm={2} md={6} lg={7}></Col>
            </Row>
        </div>
    )
}

export default Login
