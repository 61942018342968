import React, { useState } from 'react'
import { Modal, Col, Row, Button } from 'antd';
import ShowMenuCSS from './ShowMenu.module.css'

import CantaritoChicoBack from '../../images/CantaritoChicoBack.jpg'

function ShowMenu(datos) {

    const [modal, setModal] = useState(datos.datos.modal);

    

    //console.log("param datos modal", datos)
    //console.log("valor de modal en modal: ", modal)

    const cerrarModal = () => {
        //console.log("cerrando modal desde modal")
        setModal(false);
        datos.closeModal();
        datos.datos.function()
    }

    /*const action = () => {
        if(datos.datos.mesa === undefined){
            history.push("/Torder");
        }else{
            history.push("/Torder/mesa/"+datos.datos.mesa);
        }
        
        cerrarModal();
    }*/

    return (
        <div >
            <Modal
                /*title={
                    <h1>Titulo</h1>
                }*/
                closable={false}
                visible={modal}
                onCancel={cerrarModal}
                
                centered
                bodyStyle={{ padding: "0px" }}
                footer={
                    <Row>
                        <Col span={1} ></Col>
                        <Col span={22}>
                            <Button block
                                className={ShowMenuCSS.button_cancel}
                                onClick={cerrarModal}
                            >Cerrar</Button>
                        </Col>
                        <Col span={1} ></Col>
                    </Row>
                }>
                <div className={ShowMenuCSS.container}>
                    <div className={ShowMenuCSS.height_img}>
                        <img src={CantaritoChicoBack} alt="" className={ShowMenuCSS.img}></img>
                    </div>
                    <h4 className={ShowMenuCSS.h4}>{datos.datos.prod.nombre}</h4>
                    <p className={ShowMenuCSS.p}>{datos.datos.prod.descripcion}</p>

                    <Row className={ShowMenuCSS.info}>
                        <Col span={1} ></Col>
                        <Col span={7} >
                            <Row className={`${ShowMenuCSS.border} ${ShowMenuCSS.border_l}`}>
                                <Col span={24}>Precio</Col>
                                <Col span={24} className={ShowMenuCSS.p_bold}>${datos.datos.prod.precio}</Col>
                            </Row>
                        </Col>
                        <Col span={8} >
                            <Row className={ShowMenuCSS.border}>
                                <Col span={24}>Descuento</Col>
                                <Col span={24} className={ShowMenuCSS.p_bold}>{datos.datos.prod.descuento.descuento ? `$${datos.datos.prod.descuento.descuento} OFF` : '-'}</Col>
                            </Row>
                        </Col>
                        <Col span={7} >
                            <Row className={`${ShowMenuCSS.border} ${ShowMenuCSS.border_r}`}>
                                <Col span={24}>Estatus</Col>
                                <Col span={24} className={ShowMenuCSS.p_bold}>{datos.datos.prod.estatus}</Col>
                            </Row>
                        </Col>
                        <Col span={1} ></Col>
                    </Row>

                    {datos.datos.prod.descuento.nombre ? 
                        <Row style={{fontSize:"10px", marginBottom:"5px"}}>
                            <Col span={24}>Datos del descuento</Col>
                            <Col span={24}>{datos.datos.prod.descuento.nombre}</Col>
                            <Col span={24}>{datos.datos.prod.descuento.descripcion}</Col>
                        </Row>
                        :
                        null
                    }
                    
                    {
                        //console.log("no hay descuento")
                        /*<Row>
                        <Col span={1}></Col>
                        <Col span={8}>
                            <p className={ShowMenuCSS.p_bold}>{datos.datos.prod.descuento.nombre ? `¡Hoy! ${datos.datos.prod.descuento.nombre}` : ''}</p>
                        </Col>
                        <Col span={15}>
                            <p className={ShowMenuCSS.p_bold}>{datos.datos.prod.descuento.descripcion}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={8} >
                            <p className={` ${ShowMenuCSS.col_circle}`}>precio: {datos.datos.prod.precio}</p>
                        </Col>
                        <Col span={6}></Col>
                        <Col span={8} >
                            <p className={` ${ShowMenuCSS.col_circle}`}
                                style={datos.datos.prod.estatus === 'Disponible' ?
                                    { backgroundColor: "#298A08" }
                                    :
                                    { backgroundColor: "#C70039" }}>{datos.datos.prod.estatus}</p>
                        </Col>
                        <Col span={1}></Col>
                    </Row>*/}

                </div>
            </Modal>
        </div >
    )
}

export default ShowMenu
