export const PHRASE = [
    'De todas las cosas que llevas puestas, tu actitud es la más importante.',
'La motivación es la gasolina del cerebro.',
'Ir juntos es comenzar, mantenerse juntos es progresar, trabajar juntos es triunfar.',
'Trabajar duro por algo que nos interesa, se llama estrés. Trabajar duro por algo que amamos, se llama pasión.',
'No importa lo despacio que vayas, siempre y cuando no te detengas. (Confucio)',
'Si trabajas apasionadamente y realmente convencido de lo que estás haciendo, habrás encontrado la clave del éxito.',
'Esfuerzo continuo, no fuerza o inteligencia. Es la clave para liberar nuestro potencial. (Winston Churchill).',
'Sólo aquellos que se atreven a sufrir grandes fracasos son capaces de conseguir grandes éxitos. (Will Smith).',
'Locura es hacer la misma cosa una y otra vez esperando obtener diferentes resultados. (Albert Einstein). ',
'La confianza en uno mismo es el primer secreto del éxito. (Ralp Waldo Emerson).',
'Una persona inteligente no es aquella que tiene muchas ideas, sino aquella que aprovecha las pocas que tiene.',
'El éxito no se logra sólo con cualidades especiales. Sobre todo un trabajo de constancia, de método y de organización. (Víctor Hugo).',
'No esperes que las oportunidades lleguen solas. Tienes que hacer que ocurra. (Dnis Diderot).',
'Siempre que te pregunten si puedes hacer un trabajo, contesta ¡Sí! Y ponte enseguida a aprender cómo se hace. (Franklin Delano Roosevelt).',
'En chino se usan dos pinceladas para escribir la palabra crisis: una significa peligro y otra, oportunidad. En medio de una crisis, mantente atento al peligro sin perder de vista la oportunidad.',
'De una pequeña semilla puede crecer un poderoso tronco.',
'La disciplina es el ingrediente más importante del éxito. (Truman Capote).',
'Si crees que no puedes, tienes razón. Si crees que puedes, tienes razón. De cualquier forma, tendrás razón. (Henry Ford).',
'La motivación nos impulsa a empezar un hábito. El hábito, nos permite continuar. (Jim Ryum).',
'El emprendedor siempre busca el cambio, responde de él y lo utiliza como oportunidad. (Peter Drucker).',
'Trata de ser un arcoiris en la nube de alguien.',
'Dá siempre tu 100%. Pon todo lo que eres en lo mínimo que hagas. (Fernando Pessoa).',
'Un líder es alguien que conoce el camino, anda el camino y muestra el camino. (John C. Maxwell).',
'No busques los errores, busca la solución. (Henry Ford).',
'Normalmente, lo que más miedo nos da hacer es lo que más necesitamos hacer. (Timothy Ferriss).',
'Dedica tu vida a lo que apasiona. No quiero ganarme la vida, quiero vivir. (Oscar Wilde).',
'Para dirigir personas, camina detrás de ellas. (Lao Tzu).',
'Tu gran oportunidad puede ser justo donde te encuentras ahora. (Napoleon Hill).',
'El éxito de la vida consiste siempre, en seguir adelante.',
'Dale luz y la oscuridad desaparecerá por sí misma. (Erasmo).',
'La inovación distingue a un líder de un seguidor.',
'Una gran vida comienza en el interior. (Malka Maxwell).',
'Quien no ama su trabajo, aún trabajando todo el día, será un desocupado. (Facundo Cabral).',
'El 80% del éxito se basa simplemente en insistir. (Woody Allen).',
'A menudo las personas están trabajando duro en la cosa equivocada. Trabajar en la cosa correcta probablemente es más importante que trabajar duro. (Caterina Fake).',
'El éxito es la suma de pequeños esfuerzos repetidos un día sí y otro, también. (Robert Collier).',
'Si crees que puedes, ya estás a medio camino. (Theodore Roosevelt).',
'El trabajo que nunca se empieza es el que más se tarda en finalizar. (J.R.R.Tolkien).',
'Cuando pierdas, no pierdas la lección. (Dalai Lama).',
'El éxito depende de insistir. (Sófocles).',
'He aprendido que los errores pueden ser tan buenos profesores como el éxito. (Jack Welch).',
'El talento gana juegos pero el trabajo en equipo y la inteligencia, ganan campeonatos. (Michael Jordan).',
'Haz lo que puedes, con lo que tienes, donde estás. (Theodore Roosevelt).',
'Hazlo o no lo hagas, pero no lo intentes. (Yoda – Star Wars).',
'La diligencia es la madre de la buena fortuna. (Benjamin Disreali).'
];