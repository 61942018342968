import React from 'react';
import './App.css';
import './components/Cards/Cards';
import Cards from './components/Cards/Cards';
import CardsCSS from './components/Cards/Cards.module.css';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import TOrder from './components/TOrder/TOrder';
import TOrderCss from './components/TOrder/TOrder.module.css';
import GetDataOrder from './components/OrderControl/GetDataOrder';
import OrderCtrlCss from './components/OrderControl/OrderCtrl.module.css';
import Welcome from './components/Welcome/Welcome';
import WelcomeCSS from './components/Welcome/Welcome.module.css';
import Menu from './components/Menu/Menu'
import MenuCss from './components/Menu/Menu.module.css'
import Login from './components/Login/Login'
import LoginCSS from './components/Login/Login.module.css'
import User from './components/User/User';
import UserCSS from './components/User/User.module.css';
import Register from './components/Login/Register';


function App() {
  return (
    <Router>
      <div className="navbar">
        <Navbar></Navbar>
        <Switch>
          <Route path="/welcome/:mesa">
            <div className={WelcomeCSS.container}>
              <Welcome />
            </div>
          </Route>
          <Route path="/menu/:mesa">
            <div className={MenuCss.container} >
              <Menu />
            </div>
          </Route>
          <Route path="/OrderCtrl/:id" exact>
            <div className={OrderCtrlCss.container}>
              <GetDataOrder />
            </div>
          </Route>
          <Route path="/TOrder/mesa/:mesaid">
            <div className={TOrderCss.container}>
              <TOrder />
            </div>
          </Route>
          <Route path="/TOrder/:id">
            <div className={TOrderCss.container}>
              <TOrder />
            </div>
          </Route>
          <Route path="/TOrder">
            <div className={TOrderCss.container}>
              <TOrder />
            </div>
          </Route>
          <Route path="/menu" exact>
            <div className={MenuCss.container} >
              <Menu />
            </div>
          </Route>
          <Route path="/home" exact>
            <div className={CardsCSS.container} >
              <Cards /*datos={list}*/ />
            </div>
          </Route>
          <Route path="/user" exact>
            <div className={UserCSS.container}>
              <User />
            </div>
          </Route>
          <Route path="/login" exact>
            <div className={LoginCSS.container}>
              <Login />
            </div>
          </Route>
          <Route path="/register" exact>
            <div className={LoginCSS.container}>
              <Register />
            </div>
          </Route>
          <Route path="/welcome" exact>
            <div className={WelcomeCSS.container}>
              <Welcome />
            </div>
          </Route>
        </Switch>
      </div>
    </Router>
  );

}

export default App;
