import NavbarCSS from './Navbar.module.css'
import {HomeFilled,HomeOutlined, ProfileOutlined, ProfileFilled, ReadFilled, ReadOutlined, SettingFilled, SettingOutlined } from '@ant-design/icons';

const Navbar = () => {

    return (
        <div>
            <header id="navbar" className={NavbarCSS.navbar}>
                <div className={NavbarCSS.wrapper}>
                    <div id="logo" className={NavbarCSS.logo}>
                        <a href="." className={`${NavbarCSS.logo_a} ${NavbarCSS.bt_menu}`}>El Atorón</a>
                    </div>
                    <nav className={NavbarCSS.nav} >
                        <ul className={NavbarCSS.nav_ul}>
                            <div className={NavbarCSS.nav_container}>
                            <li className={NavbarCSS.li}>
                                <a href="/home" className={NavbarCSS.li_a}><HomeFilled/>Ordenes</a>
                                <a href="/home" className={NavbarCSS.icon}><HomeOutlined className={NavbarCSS.icon_item}/></a>
                            </li>
                            <li className={NavbarCSS.li}>
                                <a href="/TOrder" className={NavbarCSS.li_a}><ProfileFilled/>Tomar orden</a>
                                <a href="/TOrder" className={NavbarCSS.icon}><ProfileOutlined className={NavbarCSS.icon_item}/></a>
                            </li>
                            <li className={NavbarCSS.li}>
                                <a href="/menu" className={NavbarCSS.li_a}><ReadFilled/>Menú</a>
                                <a href="/menu" className={NavbarCSS.icon}><ReadOutlined className={NavbarCSS.icon_item}/></a>
                            </li>
                            <li className={NavbarCSS.li}>
                                <a href="/user" className={NavbarCSS.li_a}><SettingFilled/>Configuraciones</a>
                                <a href="/user" className={NavbarCSS.icon}><SettingOutlined className={NavbarCSS.icon_item}/></a>
                            </li>
                            </div>
                        </ul>
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Navbar;