import React from 'react'
import UserCSS from './User.module.css'
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import { PHRASE } from '../../Constatnts/Phrase'
import axios from 'axios';
import { BASE } from '../../Constatnts/RestService'


function User() {

    const cookies = new Cookies();
    const history = useHistory();

    const logout = async () => {
        await axios.post(BASE + '/users/logout?id=' + cookies.get('id_user'))
            .then(function doLogout(result) {
                if (result.data === true) {
                    cerrarSesion()
                } else {
                    alert("No es posible cerrar sesion");
                }
            }
            )
            .catch(function error(result) {
                alert("ocurrió un error en el cierre de sesion");
                //console.log("error al registrar usuario:", result)
            });
    }

    const goHome = () => {
        history.push("/home");
    }

    const cerrarSesion = () => {
        cookies.remove('id_user', { path: "/" })
        cookies.remove('username', { path: "/" })
        cookies.remove('rol', { path: "/" })
        cookies.remove('estatus', { path: "/" })
        history.push("/login");
        //console.log("cerrando sesion correctamente")
    }

    return (
        <div>
            {cookies.get('username') ?
                <div className={UserCSS.content}>
                    <div className={UserCSS.card}>
                        <div className={UserCSS.header}>
                            <div className={UserCSS.main}>
                                <div className={UserCSS.image}>
                                    <div className={UserCSS.hover}>

                                    </div>
                                </div>
                                <h3 className={UserCSS.username}>{cookies.get('username')}</h3>
                                <h3 className={UserCSS.rol}>{cookies.get('rol')}</h3>
                            </div>
                        </div>
                        <div className={UserCSS.body}>
                            <div className={UserCSS.left}>
                                <div className={UserCSS.phrase}>
                                    <h3 className={UserCSS.title}>Bienvenido</h3>
                                    <p className={UserCSS.text}>{PHRASE[Math.floor(Math.random() * PHRASE.length)]}</p>
                                </div>
                                <div className={UserCSS.buttons}>
                                    <div className={UserCSS.button_home}>
                                        <button className={UserCSS.home} onClick={goHome}>Home</button>
                                    </div>
                                    <div className={UserCSS.button_logout}>
                                        <button className={UserCSS.logout} onClick={logout}>Logout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                history.push("/login")
            }
        </div>
    )
}

export default User
