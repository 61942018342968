import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Input } from 'antd';
import OrderCtrlCss from './OrderCtrl.module.css';
import TOrderCss from '../TOrder/TOrder.module.css';
import { Link } from 'react-router-dom';
import ModalView from '../Modal/ModalView';
import axios from 'axios';
import { BASE } from '../../Constatnts/RestService'
import Cookies from 'universal-cookie';

function OrderCtrl({ data, id }) {

    const [list] = useState(data);
    //const { id } = useParams();
    const [object] = useState(list.filter((item) => item.id === id)[0]);//list.map((item) => item)[id]);
    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState();
    const [total, setTotal] = useState(0);
    const [monto, setMonto] = useState(0);
    const [descuento, setDescuento] = useState(0);
    const [cobroTotal, setCobroTotal] = useState(0);
    const [code, setCode] = useState("");
    const [codigoDesc, setcodigoDesc] = useState();
    const [showModalCode, setShowModalCode] = useState(false);

    //console.log("### code", code)
    //console.log("### codigoDesc", codigoDesc)

    const cookies = new Cookies();

    useEffect(() => {
        if (cookies.get('username')) {
            //console.log("username, ", cookies.get('username'))
            document.getElementById("navbar").style.display = "block";
        } else {
            //console.log("no username")
            document.getElementById("navbar").style.display = "none";
        }
        handleMonto();
        handleDescuento();
        handleCobroTotal();
        changeEstatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCode, cobroTotal, monto, descuento])

    const editEstatusProd = async (object, id) => {
        //console.log("editEstatusProd")
        //console.log("datos para editar producto ", object.producto)
        //console.log("id para editar producto ", id)
        await axios.put(BASE + '/prodsOrder/edit?id=' + id, object);
        //console.log("resultado de la actualización: ", response.data)
    }

    const editEstatusOrder = async (obj, id, est) => {
        object.estatus = est;
        object.total = (monto - descuento);

        //console.log("editEstatusOrder")
        //console.log("datos para editar producto ", obj)
        //console.log("id para editar producto ", id)
        //console.log("est para editar producto ", est)
        //console.log("cobro total NaN", object.total)

        await axios.put(BASE + '/orden/edit/estatusOrder?id=' + id + '&est=' + est, obj);
        //console.log("resultado de la actualización: ", response.data)
    }

    const changeEstatus = () => {
        //console.log("OBJECT ", object)

        const totalProds = object.producto.length;
        //console.log("--> total de productos: ", totalProds);

        const diferentes = object.producto.filter((item) => item.estatus_orden !== "sin entregar" && item.estatus_orden !== "cancelado").length;
        //console.log("--> diferentes a sin entregar ", diferentes);

        const entregados = object.producto.filter((item) => item.estatus_orden === "entregado").length;
        //console.log("--> entregados", entregados);

        const sinEntregar = object.producto.filter((item) => item.estatus_orden === "sin entregar" || item.estatus_orden === "cancelado").length;
        //console.log("--> sin entrgar", sinEntregar);

        const cancelados = object.producto.filter((item) => item.estatus_orden === "cancelado").length;
        //console.log("--> cancelados ", cancelados);

        if (diferentes <= totalProds && diferentes !== 0 && (entregados + cancelados) !== totalProds) {
            //console.log("If 1");
            editEstatusOrder(object, object.id, "atendiendo");
        }

        if ((entregados + cancelados) === totalProds && cancelados !== totalProds) {
            //console.log("If 2");
            editEstatusOrder(object, object.id, "atendido");
        }

        if (sinEntregar === totalProds || cancelados === totalProds) {
            //console.log("If 3");
            editEstatusOrder(object, object.id, "en proceso");
        }
    }

    const user = cookies.get('rol');

    ////console.log("object: ", object)

    const [counter, setCounter] = useState(
        user === "mesero" ?
            object.producto.filter((item) => item.estatus_orden === "sin entregar" || item.estatus_orden === "preparando" || item.estatus_orden === "¡listo!")
            :
            user === "chef" ?
                object.producto.filter((item) => item.estatus_orden === "¡listo!" || item.estatus_orden === "preparando")
                :
                object.producto.filter((item) => item.estatus_orden === "entregado" || item.estatus_orden === "cancelado")
    );

    /*const colorEstatus = (
        object.estatus === "en proceso" ?
            { background: " #C70039", border: " #C70039 4px solid" }
            :
            object.estatus === "atendiendo" ?
                { background: "#DF3A01", border: "#DF3A01 4px solid" }
                :
                object.estatus === "atendido" ?
                    { background: "#DBA901", border: "#DBA901 4px solid" }
                    :
                    { background: "#298A08", border: "#298A08 4px solid" });*/

    const colorEstatus = (
        object.estatus === "en proceso" ?
            { background: "#DF3A01", border: " #DF3A01 4px solid" }
            :
            object.estatus === "atendiendo" ?
                { background: "#DBA901", border: "#DBA901 4px solid" }
                :
                { background: "#298A08", border: "#298A08 4px solid" }
    );


    const colorEstatusProd = (prod) => (

        prod === "cancelado" ?
            { background: " #C70039" }
            :
            prod === "sin entregar" ?
                { background: "#DBA901" }
                :
                prod === "entregado" ?
                    { background: "#298A08" }
                    :
                    prod === "preparando" ?
                        { background: "#DF3A01" }
                        :
                        { background: "#f56f3a" }
    );

    const userShow = (

        user === "mesero" ?
            {
                chef: { display: "none" },
                cajero: { display: "none" },
                client: { display: "none" }
            }
            :
            user === "chef" ?
                {
                    mesero: { display: "none" },
                    cajero: { display: "none" },
                    client: { display: "none" }
                }
                :
                user === "cajero" ?
                    {
                        mesero: { display: "none" },
                        chef: { display: "none" },
                        client: { display: "none" }
                    }
                    :
                    user === "admin" ?
                        {
                            client: { display: "none" }
                        }
                        :
                        {
                            mesero: { display: "none" },
                            chef: { display: "none" },
                            cajero: { display: "none" }
                        }
    );

    const ticketShow = (
        user === "chef" ?
            {
                chef: { display: "none" }
            }
            :
            {}
    );

    const modalCancelProducts = () => {
        // //console.log("cancel prods ", object.producto.filter((item) => item.estatus_orden === "sin entregar" || item.estatus_orden === "preparando" || item.estatus_orden === "¡listo!"))
        setCounter(object.producto.filter((item) => item.estatus_orden === "sin entregar" || item.estatus_orden === "preparando" || item.estatus_orden === "¡listo!"))
        setModalType("cancelProd");
        setModal(true);
    }

    const modalPrepareProducts = () => {
        setCounter(object.producto.filter((item) => item.estatus_orden === "sin entregar"))
        setModalType("prepareProd");
        setModal(true);
    }

    const modalEntregaProducts = (typeUser) => {
        if (user === "mesero" || (user === "admin" && typeUser === "mesero")) {
            setCounter(object.producto.filter((item) => item.estatus_orden === "¡listo!"))
            //console.log("###entrega mesero user: ", user)
            setModalType("entregaMeseroProd");
        }
        if (user === "chef" || (user === "admin" && typeUser === "chef")) {
            setCounter(object.producto.filter((item) => item.estatus_orden === "preparando"))
            setModalType("entregaChefProd");
        }
        setModal(true);
    }

    const modalCobrarOrder = () => {
        //console.log("modal cobrar")

        object.total = cobroTotal;
        //console.log("total: ", object.total)
        //console.log("filter:", object.producto.filter((item) => item.estatus_orden === "cancelado").length)
        //console.log("lenght", object.producto.length)
        if (object.producto.filter((item) => item.estatus_orden === "cancelado").length !== object.producto.length) {
            setTotal((object.producto.filter((item) => item.estatus_orden !== "cancelado").map((item) =>
                ((item.productosInvent.precio) * (item.cantidad))).reduce(
                    (previous, current) => { return previous + current }) - descuento)
            );
            setCounter(object.producto.filter((item) => item.estatus_orden === "cancelado" || item.estatus_orden === "entregado"));
        } else {
            setCounter([])
        }

        setModalType("cobrarOrder");
        setModal(true);
    }

    const handleMonto = () => {
        ////console.log("handlemonto ", object.producto.filter((item) => item.estatus_orden !== "cancelado"))
        ////console.log("objeto ", object)
        if (object.producto.filter((item) => item.estatus_orden !== "cancelado").length !== 0) {
            let resultMonto = object.producto.filter((item) => item.estatus_orden !== "cancelado").map((item) =>
                ((item.productosInvent.precio) * (item.cantidad))).reduce((previous, current) => { return previous + current });
            //console.log("handleMonto -> resultMonto ->", resultMonto)
            setMonto(resultMonto);
        } else {
            //console.log("handleMonto -> resultMonto ->", 0)
            setMonto(0);
        }
    }

    const handleDescuento = () => {
        //console.log("aplicando descuento")
        ////console.log("objeto ", object)
        ////console.log("handle descuento ", object.producto.filter((item) => item.estatus_orden !== "cancelado" && item.productosInvent.descuento.codigo))
        if (object.producto.filter((item) => item.estatus_orden !== "cancelado").length !== 0) {
            //console.log("entra if")
            const filter = object.producto.filter((item) => item.estatus_orden !== "cancelado" && item.productosInvent.descuento.codigo);
            ////console.log("filter ", filter)
            let dto = 0;
            if (filter.length !== 0) {

                // setDescuento(object.producto.filter((item) => item.estatus_orden !== "cancelado" && item.productosInvent.descuento.codigo).map((item) =>
                //     ((item.productosInvent.descuento.descuento) * (item.cantidad))).reduce((previous, current) => { return previous + current }));

                let arrayDto = object.producto.filter((item) => item.estatus_orden !== "cancelado" && item.productosInvent.descuento.codigo && item.productosInvent.descuento.estatus === "activo").map((item) =>
                    ((item.productosInvent.descuento.descuento) * (item.cantidad)));

                //console.log("ArrayDto:", arrayDto)

                if (arrayDto.length !== 0) {
                    dto = dto + arrayDto.reduce((previous, current) => { return previous + current });
                }
            }

            if (object.descuentos === null || object.descuentos === undefined || (object.descuentos.length === 1 && object.descuentos[0] === null)) {
                //console.log("validando descuentos if")
            } else {
                //console.log("validando descuentos else")
                //console.log("filter", object.descuentos)

                let arrayDto = object.descuentos.filter((item) => item !== null || (object.descuentos.length === 1 && object.descuentos[0] === null)).map((item) =>
                    (item.descuento));

                if (arrayDto.length !== 0) {
                    dto = dto + arrayDto.reduce((previous, current) => { return previous + current });
                }
                // dto = dto + object.descuentos.filter((item) => item !== null || item !== [null]).map((item) =>
                //     (item.descuento)).reduce((previous, current) => { return previous + current });
            }
            setDescuento(dto)
            //console.log("###result descuento:", dto)
        } else {
            ////console.log("entra else")
            setDescuento(0);
        }
    }

    const handleCobroTotal = () => {
        //console.log("realizando cobro")
        let operacion = monto - descuento;
        //console.log("handleCobroTotal -> monto ->", monto)
        //console.log("handleCobroTotal -> descuento ->", descuento)
        //console.log("handleCobroTotal -> operacion ->", operacion)
        if (operacion < 0) {
            setCobroTotal(0)
        } else {
            setCobroTotal(operacion)
        }
    }

    const modalCancelOrder = () => {
        setCounter(object.producto.filter((item) => item.estatus_orden === "cancelado"));

        setModalType("cancelOrder");
        setModal(true);
    }

    const closeModal = () => {
        //console.log("CERRANDO MODAL")
        changeEstatus();
        setModal(false);
    }

    const handleProducts = (id_prod_orden) => {
        //console.log("handle products: -> ", id_prod_orden)
        //console.log("handleproduct ", object)

        //checar permisos del admin
        if ((user === "mesero" || user === "admin") && modalType === "cancelProd") {
            object.producto[id_prod_orden].estatus_orden = "cancelado";
            setCounter(object.producto.filter((item) => item.estatus_orden === "sin entregar" || item.estatus_orden === "preparando" || item.estatus_orden === "¡listo!"))

            ////console.log("handle products cancel prod", object.producto.filter((item) => item.estatus_orden === "sin entregar" || item.estatus_orden === "preparando" || item.estatus_orden === "¡listo!"))
        }
        if ((user === "mesero" || user === "admin") && modalType === "entregaMeseroProd") {
            object.producto[id_prod_orden].estatus_orden = "entregado";
            setCounter(object.producto.filter((item) => item.estatus_orden === "¡listo!"))

        }
        if ((user === "chef" || user === "admin") && modalType === "entregaChefProd") {
            object.producto[id_prod_orden].estatus_orden = "¡listo!";
            setCounter(object.producto.filter((item) => item.estatus_orden === "preparando"))
        }
        if ((user === "chef" || user === "admin") && modalType === "prepareProd") {
            object.producto[id_prod_orden].estatus_orden = "preparando";
            ////console.log("handle products prepare prod", object.producto.filter((item) => item.estatus_orden === "sin entregar"))
            setCounter(object.producto.filter((item) => item.estatus_orden === "sin entregar"))
        }

        editEstatusProd(object.producto, object.id);
        closeModal()
    }

    ////console.log("contador: ", counter.length)

    const modalBody = (
        <div >
            {counter.length > 0 ?
                <Row>
                    <Col span={16} className={`${TOrderCss.grid} ${TOrderCss.grid_left}`}>Producto</Col>
                    <Col span={8} className={`${TOrderCss.grid} ${TOrderCss.grid_right}`}>Evento</Col>
                </Row>
                :
                <div>
                    <p className={TOrderCss.grid_list}>{
                        (user === "mesero" || user === "admin") && modalType === "cancelProd" ?
                            'No hay productos por cancelar'
                            :
                            (user === "mesero" || user === "admin") && modalType === "entregaMeseroProd" ?
                                'No hay productos por entregar'
                                :
                                (user === "chef" || user === "admin") && modalType === "entregaChefProd" ?
                                    'No hay productos por entregar'
                                    :
                                    (user === "chef" || user === "admin") && modalType === "prepareProd" ?
                                        'No hay productos por preparar'
                                        :
                                        'definir texto para este user'
                    }
                    </p>
                </div>
            }
            <div>
                {object.producto.map((item, index) =>
                    ((user === "mesero" || user === "admin") && modalType === "cancelProd" && (item.estatus_orden === "sin entregar" || item.estatus_orden === "preparando" || item.estatus_orden === "¡listo!")) ?
                        <Row key={index} style={{ paddingTop: "10px" }}>
                            <Col span={16} className={TOrderCss.modalCancelProd} >{`${item.cantidad} ${item.productosInvent.nombre} ${item.descripcion_orden}`}</Col>
                            <Col span={8} style={{ position: "relative" }}>{

                                <Button className={OrderCtrlCss.buttonCancelProd} size={'small'} onClick={handleProducts.bind(this, item.id_prod_orden)}>
                                    Cancelar
                                </Button>
                            }</Col>
                        </Row>
                        :
                        ((user === "mesero" || user === "admin") && modalType === "entregaMeseroProd" && (item.estatus_orden === "¡listo!")) ?
                            <Row key={index} style={{ paddingTop: "10px" }}>
                                <Col span={16} className={TOrderCss.modalCancelProd} >{`${item.cantidad} ${item.productosInvent.nombre} ${item.descripcion_orden}`}</Col>
                                <Col span={8} style={{ position: "relative" }}>{

                                    <Button className={OrderCtrlCss.buttonCancelProd} size={'small'} onClick={handleProducts.bind(this, item.id_prod_orden)}>
                                        Entregar
                                    </Button>
                                }</Col>
                            </Row>
                            :
                            ((user === "chef" || user === "admin") && modalType === "entregaChefProd" && (item.estatus_orden === "preparando")) ?
                                <Row key={index} style={{ paddingTop: "10px" }}>
                                    <Col span={16} className={TOrderCss.modalCancelProd} >{`${item.cantidad} ${item.productosInvent.nombre} ${item.descripcion_orden}`}</Col>
                                    <Col span={8} style={{ position: "relative" }}>{
                                        <Button className={OrderCtrlCss.buttonCancelProd} size={'small'} onClick={handleProducts.bind(this, item.id_prod_orden)}>
                                            Entregar
                                        </Button>
                                    }</Col>
                                </Row>
                                :
                                ((user === "chef" || user === "admin") && modalType === "prepareProd" && item.estatus_orden === "sin entregar") ?
                                    <Row key={index} style={{ paddingTop: "10px" }}>
                                        <Col span={16} className={TOrderCss.modalCancelProd} >{`${item.cantidad} ${item.productosInvent.nombre} ${item.descripcion_orden}`}</Col>
                                        <Col span={8} style={{ position: "relative" }}>{
                                            <Button className={OrderCtrlCss.buttonCancelProd} size={'small'} onClick={handleProducts.bind(this, item.id_prod_orden)}>
                                                Preparar
                                            </Button>
                                        }</Col>
                                    </Row>
                                    :
                                    null
                                    //console.log("entrando en ultimo else")
                )}
            </div>
        </div>
    );

    //console.log("COUNTER", counter.length)
    //console.log("LENGTH", object.producto.length)

    const modalBodyCajero = (
        (user === "cajero" || user === "admin") && modalType === "cobrarOrder" && (counter.length === object.producto.length) ?
            <div >
                <Row>
                    <Col span={2} className={`${TOrderCss.grid} ${TOrderCss.grid_left}`}>No.</Col>
                    <Col span={10} className={TOrderCss.grid}>Producto</Col>
                    <Col span={8} className={`${TOrderCss.grid} `}>Descripción</Col>
                    <Col span={4} className={`${TOrderCss.grid} ${TOrderCss.grid_right}`}>Precio</Col>
                </Row>
                <br></br>
                {object.producto.map((item) =>
                    <Row key={item.id_prod_orden}>
                        <Col span={2} className={TOrderCss.grid_list} >{item.cantidad}</Col>
                        <Col span={10} className={TOrderCss.grid_list}>{item.productosInvent.nombre}</Col>
                        <Col span={8} className={TOrderCss.grid_list}>{item.descripcion_orden}</Col>
                        <Col span={4} className={TOrderCss.grid_list}>${item.productosInvent.precio}</Col>
                    </Row>)}
                <br></br>
                <footer >

                    <Row >
                        <Col span={1}></Col>
                        <Col span={9} className={TOrderCss.grid_list_right}><h2>Descuento:</h2></Col>
                        <Col span={6}></Col>
                        <Col span={8} className={TOrderCss.grid_list_right}><h2>${descuento}</h2></Col>
                    </Row>
                    <Row >
                        <Col span={1}></Col>
                        <Col span={9} className={TOrderCss.grid_list_right}><h2>Total:</h2></Col>
                        <Col span={6}></Col>
                        <Col span={8} className={TOrderCss.grid_list_right}><h2>${total}</h2></Col>
                    </Row>
                </footer>
            </div>
            :
            (user === "cajero" || user === "admin") && modalType === "cobrarOrder" ?
                <div>
                    <p className={TOrderCss.grid_list}>{"No se puede cobrar la orden hasta que los productos esten entregados o cancelados"}</p>
                    <p className={TOrderCss.grid_list}>{"No se puede cobrar la orden si todos los productos están cancelados"}</p>
                </div>
                :
                (user === "cajero" || user === "admin") && modalType === "cancelOrder" && (counter.length === object.producto.length) ?
                    <div >
                        <p className={TOrderCss.grid_list}>{"Para cancelar la orden será necesario ingresar el password del cajero o del administrador"}</p>
                        <h2 className={TOrderCss.grid_list}>{"¿Deseas cancelar la orden?"}</h2>
                        <Row>
                            <Col span={2} ></Col>
                            <Col span={20} className={`${OrderCtrlCss.cancelOrder}`}><p >Orden {object.id} {object.mesa.mesa}</p></Col>
                            <Col span={2} ></Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col span={2} ></Col>
                            <Col span={20} ><Input placeholder="Contraseña de cajero" size="small" type="password" style={{ textAlign: "center" }} /></Col>
                            <Col span={2} ></Col>
                        </Row>


                    </div>
                    :
                    <p className={TOrderCss.grid_list}>{"No se puede cancelar la orden hasta que los productos esten cancelados"}</p>
    )

    const showAddCode = (code) => {
        setShowModalCode(true)
        setcodigoDesc(code);
        setModalType("addCode");
        setModal(true);
    }

    const modalAddCode = () => {
        //console.log("Dto: ", cobroTotal)
        //checar que si la cuenta queda en 00 no se puedan agregar más códigos de dto
        setShowModalCode(false)
        setcodigoDesc(undefined);
        setCode("");
        if (cobroTotal > 0) {
            setModalType("addCode");
            setModal(true);
        } else {
            alert("La cuenta ya está en 0, no es posible agregar otro código de descuento")
        }
    }

    const handleAddCode = async () => {
        //console.log("Code: ", code)

        await axios.get(BASE + '/descuentos/findByCode?code=' + code)
            .then(function printCode(result) {
                const resp = result.data;
                //console.log("result", resp)
                if (resp === "" || resp === undefined || resp === null) {
                    //console.log("respuesta vacía")
                    return alert("codigo de descuento invalido")
                } else {
                    setcodigoDesc(resp);
                    //console.log("RESPUESTA", resp)
                    if (resp.estatus === "activo") {
                        object.descuentos = object.descuentos.concat([resp]);
                        changeEstDesc(resp);
                        handleDescuento();
                        handleCobroTotal();
                    }
                }
            })
            .catch(function printError(error) {
                //console.log("error", error)
                alert("no fue posible encontrar el codigo ingresado")
            });
    }

    const changeEstDesc = async (resp) => {
        await axios.put(BASE + '/descuentos/editEstatus?code=' + resp.codigo + '&est=utilizado')
            .then(function changeEst(response) {
                //console.log("respuesta del cambio de estatus", response.data)
                //console.log("valor de contador", counter)
            })
            .catch(function resend(response) {
                //console.log("respuesta del cambio de estatus", response.data)
                //console.log("counter changeEstDesc", counter)
                // counter = counter + 1;
                // if (!response.data) {
                //     if (counter < 3) {
                //         //console.log("entra if changeEstDesc") 
                //         setTimeout(() => {
                //             changeEstDesc(resp);
                //         }, 10000);
                //     }
                // }
            });
    }

    const modalBodyAddCode = (
        <div>
            {codigoDesc === undefined ?
                <div>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={22} >
                            <h3 className={OrderCtrlCss.h3}>¿Tienes un código de descuento?</h3>
                        </Col>
                        <Col span={1}></Col>
                    </Row>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={22} >
                            <p style={{ textAlign: "justify" }} className={OrderCtrlCss.p}>Si tienes algún código de descuento ingresalo aquí, algunos códigos de descuento tienen restricciones y otros ya vienen incluidos con los productos que ordenas</p>
                        </Col>
                        <Col span={1}></Col>
                    </Row>


                    <Row>
                        <Col span={1}></Col>
                        <Col span={22} >
                            <Input style={{ textAlign: "center" }} placeholder="Código de descuento" id="code" value={code} onInput={e => setCode(e.target.value)} />
                        </Col>
                        <Col span={1}></Col>
                    </Row>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={22} className={OrderCtrlCss.colBtn}>
                            {/* <Button block htmlType="submit" className={OrderCtrlCss.button}
                        onClick={handleAddCode}>Agregar</Button> */}
                            <button className={OrderCtrlCss.btn_desc} onClick={handleAddCode}>Agregar</button>
                        </Col>
                        <Col span={1}></Col>
                    </Row>
                </div>
                :
                <div>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={22} >
                            <h3 className={OrderCtrlCss.h3}>{codigoDesc.codigo}</h3>
                        </Col>
                        <Col span={1}></Col>
                    </Row>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={22} >
                            <h4 className={OrderCtrlCss.h4}>{codigoDesc.nombre}</h4>
                        </Col>
                        <Col span={1}></Col>
                    </Row>
                    <Row className={OrderCtrlCss.info}>
                        <Col span={1} ></Col>
                        <Col span={11} >
                            <Row className={`${OrderCtrlCss.border} ${OrderCtrlCss.border_l}`}>
                                <Col span={24}>Descuento</Col>
                                <Col span={24} className={OrderCtrlCss.p_bold}>{`$${codigoDesc.descuento} OFF`}</Col>
                            </Row>
                        </Col>
                        <Col span={11} >
                            <Row className={`${OrderCtrlCss.border} ${OrderCtrlCss.border_r}`}>
                                <Col span={24}>Estatus</Col>
                                <Col span={24} className={OrderCtrlCss.p_bold}>{codigoDesc.estatus}</Col>
                            </Row>
                        </Col>
                        <Col span={1} ></Col>
                    </Row>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={22} >
                            {
                                codigoDesc.estatus !== "activo" && showModalCode === false?
                                    <p style={{ textAlign: "center", color: "red" }} >"Upss... parece que tu código de descuentos no es valido"</p>
                                    :
                                    <p style={{ textAlign: "center" }} >{codigoDesc.descripcion}</p>
                            }

                        </Col>
                        <Col span={1}></Col>
                    </Row>
                </div>
            }
        </div>
    );


    const modalView = (
        <div>{modal === false ? <div></div> :
            modalType === "cancelProd" ?
                <div>
                    <ModalView datos={{ modal: modal, header: "Cancelar producto", modalType: modalType, body: modalBody, productos: object, function: closeModal }} closeModal={closeModal} ></ModalView>
                </div>
                :
                modalType === "entregaChefProd" ?
                    <div>
                        <ModalView datos={{ modal: modal, header: "Entregar productos", modalType: modalType, body: modalBody, productos: object, function: closeModal }} closeModal={closeModal} ></ModalView>
                    </div>
                    :
                    modalType === "entregaMeseroProd" ?
                        <div>
                            <ModalView datos={{ modal: modal, header: "Entregar productos", modalType: modalType, body: modalBody, productos: object, function: closeModal }} closeModal={closeModal} ></ModalView>
                        </div>
                        :
                        modalType === "prepareProd" ?
                            <div>
                                <ModalView datos={{ modal: modal, header: "Preparar productos", modalType: modalType, body: modalBody, productos: object, function: closeModal }} closeModal={closeModal} ></ModalView>
                            </div>
                            :
                            modalType === "cobrarOrder" ?
                                <div>
                                    <ModalView datos={{ modal: modal, header: "Cobrar orden", modalType: modalType, body: modalBodyCajero, productos: object, counter: counter, function: closeModal }} closeModal={closeModal} ></ModalView>
                                </div>
                                :
                                modalType === "cancelOrder" ?
                                    <div>
                                        <ModalView datos={{ modal: modal, header: "Cancelar orden", modalType: modalType, body: modalBodyCajero, function: closeModal }} closeModal={closeModal} ></ModalView>
                                    </div>
                                    :
                                    modalType === "addCode" ?
                                        <div>
                                            <ModalView datos={{ modal: modal, header: "¡¡ Descuentos !!", modalType: modalType, body: modalBodyAddCode, function: closeModal }} closeModal={closeModal} ></ModalView>
                                        </div>
                                        :
                                        <div></div>}
        </div>
    );

    return (
        <div className={OrderCtrlCss.order_section}>
            <Row>
                <Col xs={1} sm={2} md={4} lg={7}></Col>
                <Col xs={22} sm={20} md={16} lg={10}>
                    <div className={OrderCtrlCss.order_card}>
                        <h1 className={OrderCtrlCss.title}>{`${object.mesa.mesa}`}</h1>
                        <Row>
                            <Col span={8}></Col>

                            <Col span={16}>
                                <Row className={OrderCtrlCss.cont_estatus} style={colorEstatus}>
                                    <Col><h2 className={OrderCtrlCss.estatus} >{`${object.estatus}`}</h2></Col>
                                </Row>
                            </Col>
                        </Row>
                        <h2 className={OrderCtrlCss.sub_title}>Productos ordenados</h2>
                        <Row gutter={[16, 16]} style={{ paddingBottom: "10px" }}>
                            <Col span={2} className={`${TOrderCss.grid} ${TOrderCss.grid_left}`}>No.</Col>
                            <Col span={10} className={TOrderCss.grid}>Producto</Col>
                            <Col span={8} className={`${TOrderCss.grid} `}>Descripción</Col>
                            <Col span={4} className={`${TOrderCss.grid} ${TOrderCss.grid_right}`}>Precio</Col>
                        </Row>
                        {object.producto.map((item, index) =>
                            <div key={index}>
                                <Row gutter={[16, 16]}>
                                    <Col span={2} className={OrderCtrlCss.grid_list} >{item.cantidad}</Col>
                                    <Col span={10} className={OrderCtrlCss.grid_list}>{item.productosInvent.nombre}</Col>
                                    <Col span={8} className={OrderCtrlCss.grid_list}>{item.descripcion_orden}</Col>
                                    <Col span={4} className={OrderCtrlCss.grid_list}>${item.productosInvent.precio}</Col>
                                </Row>
                                <Row>
                                    <Col span={8}></Col>
                                    <Col span={8}></Col>
                                    <Col span={8} className={OrderCtrlCss.estatus_prod} style={colorEstatusProd(item.estatus_orden)}>{`${item.estatus_orden}`}</Col>
                                </Row>
                            </div>
                        )}

                        <div style={ticketShow.chef}>
                            <h2 className={OrderCtrlCss.sub_title}>Tiket de la orden</h2>
                            <Row gutter={[16, 16]} style={{ paddingBottom: "10px" }}>
                                <Col span={12} className={`${TOrderCss.grid} ${TOrderCss.grid_left}`}>Concepto</Col>
                                {/*<Col span={12} className={TOrderCss.grid}>Producto</Col>
                                <Col span={8} className={`${TOrderCss.grid} `}>Descripción</Col>*/}
                                <Col span={12} className={`${TOrderCss.grid} ${TOrderCss.grid_right}`}>Importe</Col>
                            </Row>
                            <div>
                                <Row >
                                    <Col span={12} className={OrderCtrlCss.grid_list_right} >Monto</Col>
                                    <Col span={12} >
                                        <Row>
                                            <Col span={6} className={OrderCtrlCss.grid_list_right} />
                                            <Col span={18} className={OrderCtrlCss.grid_list_right} >
                                                {`$${monto}`}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <div>
                                    <Row>
                                        <Col span={24} className={OrderCtrlCss.grid_list_right} >
                                            Codigos de descuento
                                        </Col>
                                    </Row>
                                </div>
                                {
                                    object.descuentos.map((item) =>
                                        item ?
                                            <Row key={`O${item.id_desc}`} 
                                            onClick={showAddCode.bind(this, item)}>
                                                <Col span={12} className={OrderCtrlCss.grid_list_right} style={{ paddingTop: "5px" }} >
                                                    {`${item.codigo}`}
                                                </Col>
                                                <Col span={12}  >
                                                    <Row >
                                                        <Col span={6} className={OrderCtrlCss.grid_list_right} />
                                                        <Col span={18} className={OrderCtrlCss.grid_list_right} style={{ paddingTop: "5px" }}>
                                                            {`$${item.descuento} OFF`}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            :
                                            null
                                            //console.log("no hay codigo")
                                    )
                                }
                                {object.producto.map((item) =>
                                    item.productosInvent.descuento.codigo && item.estatus_orden !== "cancelado" && item.productosInvent.descuento.estatus === "activo" ?
                                        <Row key={`D${item.id_prod_orden}`} >
                                            <Col span={12} className={OrderCtrlCss.grid_list_right} style={{ paddingTop: "5px" }} >
                                                {`${item.productosInvent.descuento.codigo}`}
                                            </Col>
                                            <Col span={12}  >
                                                <Row >
                                                    <Col span={6} className={OrderCtrlCss.grid_list_right} />
                                                    <Col span={18} className={OrderCtrlCss.grid_list_right} style={{ paddingTop: "5px" }}>
                                                        {`$${item.productosInvent.descuento.descuento}  X ${item.cantidad} OFF`}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        :
                                        null
                                        //console.log("no hay codigo")
                                )}
                                {
                                    object.producto.filter((item) => (item.productosInvent.descuento.codigo && item.productosInvent.descuento.estatus === "activo") && item.estatus_orden !== "cancelado").length === 0 && (object.descuentos === null || (object.descuentos.length === 1 && object.descuentos[0] === null)) ?
                                        <div key={0} className={OrderCtrlCss.grid_list_right} style={{ paddingTop: "5px" }} >
                                            No hay codigos
                                        </div>
                                        :
                                        null
                                        //console.log("result cod ", object.producto.filter((item) => item.productosInvent.descuento.codigo).length)
                                }
                            </div>
                            <div>
                                <Row >
                                    <Col span={12} className={`${OrderCtrlCss.grid_list_right} ${OrderCtrlCss.total}`} >Monto Total</Col>
                                    <Col span={12} className={`${OrderCtrlCss.total}`} >
                                        <Row>
                                            <Col span={6} className={OrderCtrlCss.grid_list_right} />
                                            <Col span={18} className={OrderCtrlCss.grid_list_right} >
                                                {`$${cobroTotal}`}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div>
                            <Row style={userShow.mesero} className={OrderCtrlCss.button_cont}>
                                <Col span={2}></Col>
                                <Col span={20}>
                                    <Button className={OrderCtrlCss.button} block onClick={modalEntregaProducts.bind(this, "mesero")}
                                    >Entregar productos</Button>
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                            <Row style={userShow.mesero} className={OrderCtrlCss.button_cont}>
                                <Col span={2}></Col>
                                <Col span={20}>
                                    <Button className={OrderCtrlCss.button} block  >
                                        <Link to={`/TOrder/${object.id}`}>
                                            Editar pedido
                                        </Link>
                                    </Button>
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                            <Row style={userShow.mesero} className={OrderCtrlCss.button_cont}>
                                <Col span={2}></Col>
                                <Col span={20}>
                                    <Button className={OrderCtrlCss.button} block onClick={modalCancelProducts}
                                    >Cancelar productos</Button>
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                            <Row style={userShow.mesero} className={OrderCtrlCss.button_cont}>
                                <Col span={2}></Col>
                                <Col span={20}>
                                    <Button className={OrderCtrlCss.button} block onClick={modalAddCode}>
                                        Agregar codigo de descuento
                                    </Button>
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                            <Row style={userShow.client} className={OrderCtrlCss.button_cont}>
                                <Col span={2}></Col>
                                <Col span={20}>
                                    <Button className={OrderCtrlCss.button} block  >
                                        <Link to={`/TOrder/${object.id}`}>
                                            Editar pedido
                                        </Link>
                                    </Button>
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                            <Row style={userShow.client} className={OrderCtrlCss.button_cont}>
                                <Col span={2}></Col>
                                <Col span={20}>
                                    <Button className={OrderCtrlCss.button} block onClick={modalAddCode}>
                                        Agregar codigo de descuento
                                    </Button>
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                            <Row style={userShow.chef} className={OrderCtrlCss.button_cont}>
                                <Col span={2}></Col>
                                <Col span={20}>
                                    <Button className={OrderCtrlCss.button} block onClick={modalPrepareProducts}
                                    >Preparar pedido</Button>
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                            <Row style={userShow.chef} className={OrderCtrlCss.button_cont}>
                                <Col span={2}></Col>
                                <Col span={20}>
                                    <Button className={OrderCtrlCss.button} block onClick={modalEntregaProducts.bind(this, "chef")}
                                    >Entregar pedido</Button>
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                            <Row style={userShow.cajero} className={OrderCtrlCss.button_cont}>
                                <Col span={2}></Col>
                                <Col span={20}>
                                    <Button className={OrderCtrlCss.button} block onClick={modalCobrarOrder}
                                    >Cobrar pedido</Button>
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                            <Row style={userShow.cajero} className={OrderCtrlCss.button_cont}>
                                <Col span={2}></Col>
                                <Col span={20}>
                                    <Button className={OrderCtrlCss.button} block onClick={modalCancelOrder}
                                    >Cancelar pedido</Button>
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                        </div>
                    </div>
                    <div>
                        {modalView}
                    </div>
                </Col>
                <Col xs={1} sm={2} md={4} lg={7}></Col>
            </Row>
        </div>
    )
}

export default OrderCtrl;